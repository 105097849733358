import gql from 'graphql-tag'
import testFagment from './TestFragment'
import testPayFagment from './TestPayFragment'
import candidatePayFragment from './CandidatePayFragment'

export const PARTNERS_QUERY = gql`
  query Partners {
    partners: partners {
      id
      name
    }
  }
`

export const GET_USERS_BY_TYPE_QUERY = gql`
  query GetUsersByType($type: String!) {
    users: getUsersByType(type: $type) {
      id
      name
    }
  }
`

export const GET_TESTS_ROOT_QUERY = gql`
  query GetTestsRoot($filterTest: FilterTest) {
    tests: getTestsRoot(filterTest: $filterTest) {
      tests {
        ...${testFagment}
      }
      totalDocs
      totalPages
    }
  }
`
export const GET_TESTS_PAYMENT = gql`
  query getPaymentTests {
    payment: getPaymentTests {
      id
      tests{
        test{...${testPayFagment}}
        paid
        toPay
        toConfirmed
        status
      }
      candidates{
        ...${candidatePayFragment}
      }
      entity{
        id
        name
      }
      vacancy{
        id 
        title
      }
      status
      amount
      date
      time
      createdAt
      updatedAt
    }
  }
`