import gql from 'graphql-tag'
import testFagment from './TestFragment'
// import testPayFagment from './TestPayFragment'
// import candidatePayFragment from './CandidatePayFragment'

export const CREATE_TEST_MUTATION = gql`
  mutation CreateTest($testInput: TestInput) {
    createTest(testInput: $testInput) {
      ...${testFagment}
    }
  }
`

export const UPDATE_TEST_MUTATION = gql`
  mutation UpdateTest($testInput: TestInput) {
    updateTest(testInput: $testInput) {
      ...${testFagment}
    }
  }
`

export const DELETE_TEST_MUTATION = gql`
  mutation DeleteTest($id: ID!) {
    deleteTest(id: $id) {
      id
    }
  }
`

export const UPDATE_TEST_STATUS_MUTATION = gql`
  mutation UpdateTestStatus($id: ID!, $status: Boolean, $reason: String) {
    updateTestStatus(id: $id, status: $status, reason:$reason) {
      ...${testFagment}
    }
  }
`
export const SET_PAY_TEST_MUTATION = gql `
  mutation setPaymentTest($payID:ID,$testID:ID,$paid:Float,$toPay:Float) {
    setPaymentTest(payID:$payID,testID:$testID,paid:$paid,toPay:$toPay) {
      id
      status
      amount
      date
      time
      createdAt
      updatedAt
    }
  }
`