<template>
  <div>
    <v-row class="d-flex flex-row justify-end align-center mt-0 px-4 py-8">
      <v-spacer></v-spacer>
      <v-sheet class="" color="grey lighten-3" min-width="100">
        Pago
      </v-sheet>
      <v-sheet class="" color="grey lighten-3 ml-2" min-width="100">
         por pagar
      </v-sheet>
    </v-row>
    <v-row class="d-flex flex-row justify-end align-center mt-0 px-0 py-0">
      <v-col cols="12" md="8"></v-col>
      <v-col cols="12" md="4" class="mr-1">
      <v-select
        v-model="filtro"
        :items="items"
        item-text="text"
        item-value="key"
        :label="$t('filter')"
        outlined
        dense
      ></v-select>
      </v-col>
    </v-row>
    <v-simple-table dense fixed-header >
      <template>
        <thead>
          <tr>
            <th class="text-left black--text">
              {{ $t("Cod.") }}
            </th>
            <th class="text-left black--text">
              {{ $t("partner_user_type_cmp") }}
            </th>
            <th class="text-left black--text">
              {{ $t("tests") }}
            </th>
            <th class="text-left black--text">
              {{ $t("Candidates") }}
            </th>
            <th class="text-left black--text">{{ $t("pUnit") }}</th>
            <th class="text-left black--text">{{ $t("vPaid") }}</th>
            <th class="text-left black--text">{{ $t("vToPaid") }}</th>
            <th class="text-left black--text">
              {{ $t("vConfirmed") }}
            </th>
            <th class="text-left black--text">
              {{ $t("date") }}
            </th>
            <th class="text-left black--text">{{ $t("status") }}</th>
            <th class="text-left black--text">{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody v-for="(pay, index) in filteredItems" :key="index">
          <tr v-for="(test, j) in pay.tests" :key="j">
            <td>
              {{ `TEST-2022000${index}` }}
            </td>
            <td>{{ pay.entity.name }}</td>
            <td>{{ test.test.title }}</td>
            <td>{{ pay.candidates.length }}</td>
            <td>{{ currencyFormatter(test.test.price) }}</td>
            <td>
              {{
                currencyFormatter(
                  getSubTotal(pay.candidates.length, test.test.price)
                )
              }}
            </td>
            <td>{{ currencyFormatter(percentage(test.test.price)) }}</td>
            <td>
              {{ test.toConfirmed ? currencyFormatter(test.toConfirmed) : 0 }}
            </td>
            <td>{{ formatDate(pay.createdAt) }}</td>
            <td>
              <v-chip small :color="test.status === 'pending' ? 'error' : 'primary'">
                {{ $t(test.status) }}
              </v-chip>
            </td>
            <td>
              <v-row class="d-flex flex-row justify align-center">
                <v-btn
                  :disabled="test.toConfirmed ? true : false"
                  color="blue"
                  icon
                  small
                  dark
                  @click="
                    $emit(
                      'sendTest',
                      pay,
                      j,
                      getSubTotal(pay.candidates.length, test.test.price),
                      percentage(test.test.price)
                    )
                  "
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                </v-btn>
              </v-row>
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import moment from "moment";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  name: "TableScroll",
  props: {
    payment: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    filtro: undefined,
  }),
  mixins: [replaceSpecialCharsMixins],
  watch: {},
  computed: {
    items() {
      return [
        { text: this.$t("pending"), key: "pending" },
        { text: this.$t("paid"), key: "paid" },
      ];
    },

    filteredItems() {
      let Items = this.payment;
      if (this.filtro==="paid") {
        let resultItems = Items;
        const text = this.replaceSpecialChars(this.filtro);
        return (resultItems = resultItems.filter((element) => {
          element.tests = element.tests.filter((el) => {
            if (this.replaceSpecialChars(el.status).includes(text)) {
              return el;
            }
          });
          return element.tests;
        }));
      }else{
        return this.payment;
      }
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        moment.locale(this.$i18n.locale);
        return moment(date).format("L");
      }
    },
    currencyFormatter(price) {
      const locale = "pt-MZ";
      const currency = "MZN";
      let mtnLocale = Intl.NumberFormat(locale, {
        style: "currency",
        currency,
      });
      return mtnLocale.format(price).replace("n", "");
    },
    getSubTotal(qt, price) {
      return qt * price;
    },
   
    percentage(price) {
      let total = this.payment[0].candidates.length * price;
      let percentage = (total / 100) * 70;
      return percentage;
    },
  },
  created() {},
};
</script>

